import React, { useState, useEffect, useCallback } from 'react';
import {words200 } from "./200_common_words_with_numbers"
import { Link } from "react-router-dom"
const wordList =  words200.word_list;
const startWord = 1-1;


const WordDisplay: React.FC = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(startWord);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        if (currentWordIndex < startWord+50) {
          setCurrentWordIndex((prevIndex) => prevIndex + 1);
        } else {
          // Alert when spacebar is pressed after the last word
          alert('Last word reached! Start again. :-)');
          setCurrentWordIndex(() => startWord);
        }
      }
    },
    [currentWordIndex]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div style={{ textAlign: 'center', fontSize: '124px' }}>
        <p>{wordList[currentWordIndex].word}</p>
      </div>
      <div className="w-100 text-center mt-2">
          <Link to="/english_menu">Tilbake</Link>
      </div>
    </>
  );
};

export default WordDisplay;
