//import React, { useState } from "react"
import { Card } from "react-bootstrap"
//import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"

export default function Dashboard() {
  //const [error, setError] = useState("")
  //const { currentUser, logout } = useAuth()
  //const navigate = useNavigate()
  //console.log(JSON.stringify(currentUser));

  
  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Tjenester for å lære engelsk</h2>

          <Link to="/read_words2_50" className="btn btn-primary w-100 mt-3">
            Engelsk: Read Words 50
          </Link>
          <Link to="/read_words2_100" className="btn btn-primary w-100 mt-3">
            Engelsk: Read Words 100
          </Link>
          <Link to="/read_words2_150" className="btn btn-primary w-100 mt-3">
            Engelsk: Read Words 150

          </Link>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/">Tilbake</Link>
      </div>
    </>
  )
}