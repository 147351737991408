import React from "react"
import Signup from "./Signup"
import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthContext"
import { Routes , Route } from "react-router-dom"
import Dashboard from "./Dashboard"
import Login from "./Login"
import PrivateRoutes from "./PrivateRoutes"
import ForgotPassword from "./ForgotPassword"
import UpdateProfile from "./UpdateProfile"
import ReadWords from "./ReadWords"
import ReadWords2 from "./ReadWords2"
import ReadWords2w50 from "./ReadWords2_50"
import ReadWords2w100 from "./ReadWords2_100"
import ReadWords2w150 from "./ReadWords2_150"
import Multiplication7 from "./Multiplication7"
import EnglishMenu from "./EnglishMenu"
import MathMenu from "./MathMenu"
import MultiplicationSummary from "./MultiplicationSummary"
import EditLanguage from "./EditLanguage"

function App() {
  const words = ['if', 'I', 'has', 'one', 'banana'];
  return (
      <AuthProvider>
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="w-100" style={{ maxWidth: "800px" }}>
          
            <Routes>
              
              <Route element={<PrivateRoutes/>}>
                <Route path='/' element={<Dashboard/>} />
                <Route path='/read_words' element={<ReadWords words={words}/>} />
                <Route path='/read_words2' element={<ReadWords2/>} />
                <Route path='/read_words2_50' element={<ReadWords2w50/>} />
                <Route path='/read_words2_100' element={<ReadWords2w100/>} />
                <Route path='/read_words2_150' element={<ReadWords2w150/>} />
                <Route path='/multiplication' element={<Multiplication7/>} />
                <Route path='/multiplication_summary' element={<MultiplicationSummary/>} />
                <Route path='/english_menu' element={<EnglishMenu/>} />
                <Route path='/math_menu' element={<MathMenu/>} />
                <Route path='/update-profile' element={<UpdateProfile/>} />
                <Route path='/edit_language' element={<EditLanguage/>} />
              </Route>
              <Route path='/signup' element={<Signup/>} />
              <Route path='/login' element={<Login/>} />
              <Route path='/forgot-password' element={<ForgotPassword/>} />
             

            </Routes>
          
          </div>
        </Container>
       </AuthProvider>
  )
}

export default App