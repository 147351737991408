import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"

import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase"


export default function Signup() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    try {
      setError("")
      setLoading(true)
      //https://firebase.google.com/docs/reference/rest/auth
      //https://github.com/voidranjer/color-palette/blob/Part-2/src/App.js
      //const IDToken = await signup(emailRef.current.value, passwordRef.current.value)
      const passwordValue = passwordRef.current.value
      const emailValue = emailRef.current.value

     // signup(emailRef.current.value, passwordRef.current.value)
      signup(emailValue, passwordValue)
      .then((userCredential) => {
        console.log("userCred"+ JSON.stringify(userCredential))
            const userID = userCredential.user.uid;
            const docRef = doc(collection(db, "users"), userID)
            const payload = {uid:userID, name:"NN", email:emailValue, isAdmin:false };
            setDoc(docRef, payload);
      })

      //navigate("/")
      
      navigate("/login")
    } catch (err){
      setError("Failed to create an account"+ JSON.stringify(err))
    }

    setLoading(false)
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error ? <Alert severity="danger">{error}</Alert> : <Alert severity="info">Password recuires 6 characters</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </>
  )
}