/**
 * get tasks and shuffle them
 * get the userObject 
 * get the statDocs (TSTTS) from firestore and shuffle them
 * 
 * label A
 * form the information in the statDocs find next task and remember the time
 * show the task
 * get input from user
 * eveluate the user input make user Behaviour object, make new stat object and add to state
 * -save new stat and user behaviour to firestore
 * show to user whether the answer is right or wrong
 * get signal from user for next task
 * go to label A
 */



import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom"
import {multasks,Task2} from "./multasks"
import { collection, addDoc, doc, setDoc, getDocs  } from "firebase/firestore";
import { db } from "../firebase"
import { useAuth } from "../contexts/AuthContext"  


import './multiplication2.css';

// type to store user activity
interface UserBehavior {
  taskId: string;
  userAnswer: string;
  answerTimestamp: Date;
  taskGivenTimestamp: Date;
  verdict:number;
}

// type to store the stat of how well the user have answerd a particular task.  The Small Times Table Status (TSTTS)
interface TSTTS {
  taskId: string;
  repetitionLevel:number; // after days: 0,3,7,21,42,84
  levelConfirmedTimestamp:Date; // time of the confirmation of a level
  nextRepetitionStart: Date;    // date the next repetioion should start
  confirmedInARowAfterLevel:number;
  lastAnswerTimestamp: Date;
  numberOfTimesAnswered:number
}

interface CurrentTask {
  task:Task2
  timeGiven:Date
}

const repetitionLevelTimeTSTT = [0,3,7,21,42,84,84,84,84,84,84,]

function shuffle(array:any[]) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


const TaskComponent: React.FC<{ tasks: Task2[] }> = ({ tasks }) => {
  const {currentUser } = useAuth()
  const [TSTTSs,setTSTTSs] = useState<TSTTS[]>([]); // to do change default

  const [currentTask,setCurrentTask] = useState<CurrentTask|null>(null); // to do change default
  const [userAnswer, setUserAnswer] = useState<string>('');
  const [lastUserBehavior, setLastUserBehavior] = useState<UserBehavior|null>(null);

  const myInput = useRef<any>(null);
  const myButton = useRef<any>(null);




  // load state objects for the user for this service
  useEffect(()=>{
    console.log("UserSet?")
    if (!currentUser){return}

    console.log("UserSet!")
    const  getUserTSTTS_ = ()=> {
      const userDocRef = doc(collection(db, "users"),currentUser.uid)
      const collectionRef = collection(userDocRef,"the_small_times_table_v1_stats")
      getDocs(collectionRef).then ((snapshot)=>{
        //const docs = snapshot.docs.map((doc_)=>({...doc_.data()}))
        const s:TSTTS[] = snapshot.docs.map<TSTTS>((doc_) =>{
          let d =doc_.data(); 
          return {
            taskId:d.taskId,
            repetitionLevel: d.repetitionLevel,
            levelConfirmedTimestamp:new Date(d.levelConfirmedTimestamp), 
            nextRepetitionStart: new Date(d.nextRepetitionStart),
            confirmedInARowAfterLevel:d.confirmedInARowAfterLevel,
            lastAnswerTimestamp: new Date(d.lastAnswerTimestamp),
            numberOfTimesAnswered: d.numberOfTimesAnswered
          }
        })
/*
        var s:TSTTS[] = docs.map<TSTTS>((d) =>(
          {
            taskId:d.taskId,
            repetitionLevel: d.repetitionLevel,
            levelConfirmedTimestamp:new Date(d.levelConfirmedTimestamp), 
            nextRepetitionStart: new Date(d.nextRepetitionStart),
            confirmedInARowAfterLevel:d.confirmedInARowAfterLevel,
            lastAnswerTimestamp: new Date(d.lastAnswerTimestamp) 
          }
        ))
*/

        //console.log("docs len inner:"+docs.length)
        //console.log("docs:"+JSON.stringify(docs))

        //const s:TSTTS[] = docs as TSTTS[]
        console.log("s:"+JSON.stringify(s))
        shuffle(s)
        if (!s){ console.log("## bad structure ##")}
        findNSetNextTask_(s);
        setTSTTSs(s)
      })
    }
    
    getUserTSTTS_()
    //console.log("TSTTSs len outer:"+TSTTSs.length)
    myInput.current?.focus()
   
  },[currentUser])  

  const getRepetitionTask_ = (s:TSTTS[]):string|undefined=>{
    if(!s){return undefined}
    const nowD = new Date()
    //console.log("getRepetitionTask:"+JSON.stringify(s)+ "d:" + nowD)
    return (s.find((e:TSTTS)=>{
      //console.log("now:"+nowD.toDateString()+ "nr: "+ e.nextRepetitionStart.toDateString+ " now < nr "+ (nowD > e.nextRepetitionStart))
      return nowD > e.nextRepetitionStart
    }))?.taskId
  }

  const getUnfamiliarTask_ = (s:TSTTS[]):string|undefined=>{
    if(!s){return undefined}
    return (tasks.find((e:Task2)=>{ return !(s.find((ie)=>{return e.id ===ie.taskId})) }))?.id
  }
  const getWrongAnsweredTask_ = (s:TSTTS[]):string|undefined=>{
    return (s?.find((e:TSTTS)=>{return (e.confirmedInARowAfterLevel === 0 && e.repetitionLevel===0) }))?.taskId
  }
  const getCorrectAnsweredTask_ = (s:TSTTS[]):string|undefined=>{
    return (s?.find((e:TSTTS)=>{return (e.confirmedInARowAfterLevel > 0) }))?.taskId
  }
  
  const getPrematureRepetitionTask_ = (s:TSTTS[]):string|undefined=>{
    return (s?.find((e:TSTTS)=>{return e.repetitionLevel !== 0 }))?.taskId
  }

  const getTSTTSIndex_ = (s:TSTTS[],taskId:string)=>{
    return s.findIndex((e)=> (e.taskId===taskId))
  }

  const addTSTTS_ = (s:TSTTS[], inStat:TSTTS):TSTTS[] => {
    //console.log("addTSTTS")
    //console.log("s len b:"+s.length)
    var newS:TSTTS[] = [...s, inStat]
    //console.log("newS len a:"+newS.length)
    //console.log("s len au:"+s.length)
    //console.log("newS:" +JSON.stringify(newS))
    //console.log("s:" +JSON.stringify(s))

    return newS
  }

  //function is first picking a task starting with 
  //repetitiontask if they dont exist it is picking
  //an unfamiliar task for the user if it do not existing it is picking
  //an task in progress of being promotet to the next level of repetition then
  //wrong answered tasks that the user need to learn
  //in practic it is enty for taskt an then just giving the user repetiton taskt too early.   
  const findNextTask_ = (s:TSTTS[]):number=>{
    console.log("find task")
    //console.log("TSTTSs: "+JSON.stringify(TSTTSs))

    var nextTaskId:string|undefined
    nextTaskId = getRepetitionTask_(s)
    if (nextTaskId){console.log("RepetitionTask")}
    if(nextTaskId === undefined){
      nextTaskId = getUnfamiliarTask_(s)
      if (nextTaskId){console.log("UnfamiliarTask")}
    }
    if(nextTaskId === undefined){
      nextTaskId = getCorrectAnsweredTask_(s)
       if (nextTaskId){console.log("CorrectAnsweredTask")}
    }
    if(nextTaskId === undefined){
      nextTaskId = getWrongAnsweredTask_(s)
       if (nextTaskId){console.log("WrongAnsweredTask")}
    }
    if(nextTaskId === undefined){
      nextTaskId = getPrematureRepetitionTask_(s)
       if (nextTaskId){console.log("PrematureRepetitionTask")}
    }
    const n1:number = tasks.findIndex((e)=> (e.id===nextTaskId))
    console.log("Task number:" +n1+" Task id:"+nextTaskId)
    return n1;
  }
  // this function create the userbehaviour object and the TSTTS object and 
  // To do write to the db async to make the UI more responcive.

const makeUserBehaviorObject_ =  (s:TSTTS[],currentTask:CurrentTask):{userBehavior:UserBehavior,statTSTTS:TSTTS}|undefined => {
  //if (!currentTask){return undefined}
    const userBehavior: UserBehavior = {
      taskId: currentTask.task.id ,
      userAnswer,
      answerTimestamp: new Date(),
      taskGivenTimestamp: currentTask.timeGiven, // Assuming task given timestamp is when the task is rendered
      verdict: currentTask ? userAnswer.localeCompare(currentTask.task.correctAnswer) :-1
    };
  var nextRepetitionStart:Date
  const statIndex = getTSTTSIndex_(s,currentTask.task.id)
    var statTSTTS:TSTTS


    // not found
    if (statIndex===-1) {
      const confirmedInARowAfterLevel = (userBehavior.verdict===0) ? 1:0
      //var nextRepetitionStart:Date = new Date()
      nextRepetitionStart = new Date()
      nextRepetitionStart.setDate((new Date()).getDate() +  365) // one year from now
      const numberOfTimesAnswered:number = 0
      
      statTSTTS ={
        taskId: currentTask.task.id,
        repetitionLevel:0,
        levelConfirmedTimestamp:new Date(), 
        nextRepetitionStart: nextRepetitionStart,
        confirmedInARowAfterLevel:confirmedInARowAfterLevel,
        lastAnswerTimestamp: new Date(),
        numberOfTimesAnswered
      }

    } else {
      const statDoc = s[statIndex]
      console.log(JSON.stringify(statDoc));
      //got it right two in a row and it is time for repetition
      if (userBehavior.verdict!==0) {
        const confirmedInARowAfterLevel = 0
      nextRepetitionStart = new Date()
      nextRepetitionStart.setDate((new Date()).getDate() +  365) // one year from now
      const numberOfTimesAnswered:number = statDoc.numberOfTimesAnswered
      
      statTSTTS ={
        taskId: currentTask.task.id,
        repetitionLevel:0,
        levelConfirmedTimestamp:new Date(), 
        nextRepetitionStart: nextRepetitionStart,
        confirmedInARowAfterLevel:confirmedInARowAfterLevel,
        lastAnswerTimestamp: new Date(),
        numberOfTimesAnswered
      }


      } else if (statDoc.confirmedInARowAfterLevel>=2 && statDoc.nextRepetitionStart < new Date()) {
        
        var repetitionLevel:number
        if (statDoc.repetitionLevel>=5){
          repetitionLevel=5;
        } else {
          repetitionLevel = statDoc.repetitionLevel + 1
        }
        const daysFromNow:number = repetitionLevelTimeTSTT[repetitionLevel]
        const levelConfirmedTimestamp = new Date();
        //var nextRepetitionStart:Date = new Date()
        nextRepetitionStart= new Date()
        nextRepetitionStart.setDate(levelConfirmedTimestamp.getDate() +daysFromNow);
        const confirmedInARowAfterLevel=0
        const lastAnswerTimestamp= new Date()
        const numberOfTimesAnswered = statDoc.numberOfTimesAnswered+1;
        statTSTTS = {
          taskId: statDoc.taskId,
          repetitionLevel,
          levelConfirmedTimestamp,
          nextRepetitionStart,
          confirmedInARowAfterLevel,
          lastAnswerTimestamp,
          numberOfTimesAnswered
        }
      //got it right one more time in a row
      } else {
        const confirmedInARowAfterLevel=statDoc.confirmedInARowAfterLevel+1;
        const numberOfTimesAnswered = statDoc.numberOfTimesAnswered+1;
        statTSTTS ={
          ...statDoc,
          confirmedInARowAfterLevel,
          numberOfTimesAnswered
        }
      }
    }
    return {userBehavior,statTSTTS}
  }


  const handleUserAnswer = async (e:any) => {
    e.preventDefault()
    //console.log("handleUserAnswer")
    if (!currentTask){return}
    //console.log("handleUserAnswer w/ currentTask")
    
    var s = TSTTSs
    const a  = makeUserBehaviorObject_(s,currentTask)
    if (!a){return}

    const {userBehavior, statTSTTS} = a

    s = addTSTTS_(s,statTSTTS)

    setTSTTSs(s)
    setLastUserBehavior(userBehavior)

    // write to the db
    const userDocRef = doc(collection(db, "users"),currentUser.uid)
    const statDocRef = doc(collection(userDocRef,"the_small_times_table_v1_stats"), currentTask.task.id )
    addDoc(collection(userDocRef, "the_small_times_table_v1_activity"), userBehavior )
    setDoc(statDocRef,statTSTTS)
    
  }

  const handleNextTask = () => {
    if(lastUserBehavior){
       orgTSTTSs_(TSTTSs,lastUserBehavior.taskId)
    }
    setLastUserBehavior(null)
    setUserAnswer('');
    findNSetNextTask_(TSTTSs);
      myInput.current?.focus()
  };

  const findNSetNextTask_ = (s:TSTTS[]) => {
    const taskIndex = findNextTask_(s)
    const task:Task2 = tasks[taskIndex]
    setCurrentTask({task,timeGiven: new Date()})
  }

  const orgTSTTSs_ = (s:TSTTS[],taskId:string):TSTTS[]|undefined => {
    //console.log("org s")
    if (!s){return undefined}
    //console.log("org s + s"+ JSON.stringify(s))
    const stat_:TSTTS|undefined = s.find((e)=>(e.taskId===taskId))
    if (!stat_){return undefined}
    var newS:TSTTS[] =s.filter((e)=> (e.taskId!==taskId))
    newS.push(stat_)
    //setTSTTSs(newS)
    return newS
  }

  return (
    <div>
      <div className="container" style={{ fontSize: '24px' }}>
        <form onSubmit={handleUserAnswer}>
          <div className="row">
          
            <p className={lastUserBehavior!=null ? "column hidden":"column visible"}>{currentTask?.task.field1 +" x "+currentTask?.task.field3 + "="}</p>
            <input disabled={lastUserBehavior!=null} 
              ref={myInput}
              className={lastUserBehavior!=null ? (lastUserBehavior?.verdict ===0 ? "column w-40 correct":"column w-40 wrong") : "column w-40"}
              type="text"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
            />
            <button className={lastUserBehavior!=null ? "column hidden":"column visible"} type="submit">Lever</button>
          </div>
        </form>
        <div className={lastUserBehavior!=null ? "row visible" : "row hidden"}>
          <p className="column">{currentTask?.task.field1 +" x "+currentTask?.task.field3 + "="}</p>
          <p className="column"> {currentTask?.task.correctAnswer} </p>
          {lastUserBehavior?.verdict ===0 ? <p className="column correct"> Du svarte riktig</p> : <p className="column wrong"> Du svarte feil</p> }
          <button ref={myButton} onClick={handleNextTask}>Neste oppgave</button>
        </div>
        
      </div>
        <div className="w-100 text-center mt-2">
        <Link to="/math_menu">Tilbake</Link>
      </div>

    </div>        
  );
};



const App: React.FC = () => {
  //let multasks_s = shuffle(multasks)
  return (
    <div>
      <h1>Multipliser</h1>
      <TaskComponent tasks={shuffle(multasks)} />
    </div>
  );
};

export default App;
