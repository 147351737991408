import React, { useState, useEffect } from 'react';

interface Props {
 words: string[];
}

const TypeWriter: React.FC<Props> = ({ words }) => {
 const [currentIndex, setCurrentIndex] = useState(0);
 const [currentWord, setCurrentWord] = useState('');
 const [displayText, setDisplayText] = useState('');

 useEffect(() => {
    let displayIndex = 0;
    const typeInterval = setInterval(() => {
      if (displayIndex === currentWord.length) {
        clearInterval(typeInterval);
      } else {
        setDisplayText(currentWord.slice(0, displayIndex + 1));
        displayIndex++;
      }
    }, 100);

    return () => {
      clearInterval(typeInterval);
    };
 }, [currentWord]);

 useEffect(() => {
    if (currentIndex >= words.length) {
      setCurrentIndex(0);
      alert('Finished typing all words!');
    } else {
      setCurrentWord(words[currentIndex]);
    }
 }, [currentIndex, words]);
/*
 const handleKeyPress = (w:Window, event: React.KeyboardEvent) => {
    if (event.key === ' ') {
      if (currentIndex === words.length - 1) {
        alert('Finished typing all words!');
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }
 };
*/
const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ') {
      if (currentIndex === words.length - 1) {
        alert('Finished typing all words!');
      } else {
        console.log(currentIndex + 1);
        setCurrentIndex(currentIndex + 1);
      }
    }
};


/*
 useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
 }, [currentIndex, words]);
*/
useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === ' ') {
            if (currentIndex === words.length - 1) {
                alert('Finished typing all words!');
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
        window.removeEventListener('keydown', handleKeyPress);
    };
}, []);

 return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <h1>{displayText}</h1>
    </div>
 );
};

export default TypeWriter;