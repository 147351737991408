import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { collection, doc, setDoc,updateDoc,getDoc } from "firebase/firestore";
import { db } from "../firebase"


export default function UpdateProfile() {
  const nameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [ userObject, setUserObject ] = useState()
  const navigate = useNavigate()


useEffect( ()=>{
  const fetchUserObject= async ()=>{
      const userDocRef = doc(collection(db, "users"),currentUser.uid)
      getDoc(userDocRef).then ((snapshot)=>{
        const userO = snapshot.data()
        console.log(userO)
        setUserObject(userO)
      }).catch((e) => console.log(e))

  }
  fetchUserObject()
    // const payload = {uid:userID, name:"", email:emailValue };
  },[currentUser, setUserObject])

  function handleChangeName() {
    const updateUserDoc= async () => {
      const userDocRef = doc(collection(db, "users"),currentUser.uid)
      const payload= {name:nameRef.current.value}
      console.log(payload)
      console.log({...userObject,...payload})
      await updateDoc(userDocRef,payload)
      setUserObject({...userObject,...payload})
    }
    updateUserDoc();

  }

  function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    const promises = []
    setLoading(true)
    setError("")

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }

    Promise.all(promises)
      .then(() => {
        navigate("/")
      })
      .catch(() => {
        setError("Failed to update account")
      })
      .finally(() => {
        setLoading(false)
      })
  }

const  handleUpdateUser =async () => {
  try {
        //var collectionRef;
         const docRef = doc(collection(db, "users"), currentUser.uid)

        const payload = {uid:currentUser.uid, name:"", email:currentUser.email, isAdmin:true };
        await setDoc(docRef, payload);
        //const subCollectionRef = collection(docRef, "Multiplication3");
        console.log( JSON.stringify(payload))
      } catch (err){
        console.log( JSON.stringify(err))
      }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Update Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Button visibility="visible" onClick={handleChangeName} >Create UserSpace if the service do not work</Button>



          <Form onSubmit={handleSubmit}>
            <Form.Group id="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="email"
                ref={nameRef}
                required
                defaultValue={userObject?.name}
              />
              <Button visibility="visible" onClick={handleUpdateUser} >Update name</Button>
            </Form.Group>


            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                ref={emailRef}
                required
                defaultValue={currentUser.email}
              />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/">Cancel</Link>
      </div>
    </>
  )
}