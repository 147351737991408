//import React, { useState } from "react"
import { Card } from "react-bootstrap"
//import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"

export default function Dashboard() {
   
  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Tjenester for å lære matematikk</h2>

          <Link to="/multiplication" className="btn btn-primary w-100 mt-3">
            Matte: Øv på den lille gangetabellen
          </Link>

          <Link to="/multiplication_summary" className="btn btn-primary w-100 mt-3">
            Matte: Se oversikt over din øving på den lille gangetabellen
          </Link>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/">Tilbake</Link>
      </div>
    </>
  )
}