import React, { useState, useEffect, useCallback } from 'react';
import {words200 } from "./200_common_words_with_numbers"
const wordList =  words200.word_list;
// const wordsList: string[] = ['if', 'I', 'has', 'one', 'banana'];


const WordDisplay: React.FC = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Space') {
//        if (currentWordIndex < wordsList.length - 1) {
        if (currentWordIndex < wordList.length - 1) {
          setCurrentWordIndex((prevIndex) => prevIndex + 1);
        } else {
          // Alert when spacebar is pressed after the last word
          alert('Last word reached!');
        }
      }
    },
    [currentWordIndex]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div style={{ textAlign: 'center', fontSize: '24px' }}>
      <p>{wordList[currentWordIndex].word}</p>
    </div>
  );
};

export default WordDisplay;
