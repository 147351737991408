import React, { useState, useEffect} from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, setDoc, query, getDocs } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db } from "../firebase"

const EditLanguage: React.FC = () => {
  const [languageCode, setLanguageCode] = useState('');
  const [name, setName] = useState('');
  const [languages, setLanguages] = useState<any[]>([]); // Array to store languages

  useEffect(() => {
    async function fetchLanguages() {
      try {
        const languagesCollection = collection(db, 'words_with_meaning', 'languages');
        const languagesSnapshot = await getDocs(languagesCollection);

        const fetchedLanguages: any[] = [];
        languagesSnapshot.forEach((doc) => {
          fetchedLanguages.push(doc.data());
        });

        setLanguages(fetchedLanguages);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    }

    fetchLanguages();
  }, []);

  const handleAddLanguage = async () => {
    // ... (existing code remains unchanged)
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Language code"
        value={languageCode}
        onChange={(e) => setLanguageCode(e.target.value)}
      />
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <button onClick={handleAddLanguage}>Add</button>

      {/* Display list of languages */}
      <div>
        <h2>List of Languages:</h2>
        <ul>
          {languages.map((language, index) => (
            <li key={index}>
              Language Code: {language.language_code}, Name: {language.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EditLanguage;
