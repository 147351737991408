/**
 * get tasks and shuffle them
 * get the userObject 
 * get the statDocs (TSTTS) from firestore and shuffle them
 * 
 * label A
 * form the information in the statDocs find next task and remember the time
 * show the task
 * get input from user
 * eveluate the user input make user Behaviour object, make new stat object and add to state
 * -save new stat and user behaviour to firestore
 * show to user whether the answer is right or wrong
 * get signal from user for next task
 * go to label A
 */



import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import { multasks,Task2} from "./multasks"
import { collection, doc, getDocs  } from "firebase/firestore";
import { db } from "../firebase"
import { useAuth } from "../contexts/AuthContext"  


import './multiplication2.css';


// type to store the stat of how well the user have answerd a particular task.  The Small Times Table Status (TSTTS)
interface TSTTS {
  taskId: string;
  repetitionLevel:number; // after days: 0,3,7,21,42,84
  levelConfirmedTimestamp:Date; // time of the confirmation of a level
  nextRepetitionStart: Date;    // date the next repetioion should start
  confirmedInARowAfterLevel:number;
  lastAnswerTimestamp: Date;
}


const TaskComponent: React.FC<{ tasks: Task2[] }> = ({ tasks }) => {
  const {currentUser } = useAuth()
  const [TSTTSs,setTSTTSs] = useState<TSTTS[]>([]); // to do change default





  // load state objects for the user for this service
  useEffect(()=>{
    console.log("UserSet?")
    if (!currentUser){return}

    console.log("UserSet!")
    const  getUserTSTTS_ = ()=> {
      const userDocRef = doc(collection(db, "users"),currentUser.uid)
      const collectionRef = collection(userDocRef,"the_small_times_table_v1_stats")
      getDocs(collectionRef).then ((snapshot)=>{
        const s:TSTTS[] = snapshot.docs.map<TSTTS>((doc_) =>{
          let d =doc_.data(); 
          return {
            taskId:d.taskId,
            repetitionLevel: d.repetitionLevel,
            levelConfirmedTimestamp:new Date(d.levelConfirmedTimestamp), 
            nextRepetitionStart: new Date(d.nextRepetitionStart),
            confirmedInARowAfterLevel:d.confirmedInARowAfterLevel,
            lastAnswerTimestamp: new Date(d.lastAnswerTimestamp) 
          }
        })

        console.log("s:"+JSON.stringify(s))
        if (!s){ console.log("## bad structure ##")}
        setTSTTSs(s)
      })
    }
    
    getUserTSTTS_()
   
  },[currentUser])  


  const getTaskSummeryString = (taskId:string):string=>{
    const t:Task2|undefined = multasks.find((t)=>(t.id===taskId))
    if (t) {return t.field1+ " " +t.field2 +" " + t.field3 + " = "+ t.correctAnswer}
    else { return "#"}
  }


  return (
    <div>
      <div className="container" style={{ fontSize: '24px' }}>
        <ul>
        {TSTTSs.map((sat)=>{return <li key={sat.taskId}>{" " +getTaskSummeryString(sat.taskId)+" | repetition level:"+ sat.repetitionLevel+" confirmedInARowAfterLevel:"+ sat.confirmedInARowAfterLevel }</li>}       )}
        </ul>
       
        
      </div>
        <div className="w-100 text-center mt-2">
        <Link to="/math_menu">Tilbake</Link>
      </div>

    </div>        
  );
};



const App: React.FC = () => {
  //let multasks_s = shuffle(multasks)
  return (
    <div>
      <h1>Multipliser</h1>
      <TaskComponent tasks={multasks} />
    </div>
  );
};

export default App;
