import React, { useState,useEffect } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"

import './multiplication2.css';


export default function Dashboard() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const [ userObject, setUserObject ] = useState()
  const navigate = useNavigate()
  console.log(JSON.stringify(currentUser));

  

  useEffect( ()=>{
  const fetchUserObject= async ()=>{
      const userDocRef = doc(collection(db, "users"),currentUser.uid)
      getDoc(userDocRef).then ((snapshot)=>{
        const userO = snapshot.data()
        console.log(userO)
        setUserObject(userO)
      }).catch((e) => console.log(e))

  }
  fetchUserObject()
    // const payload = {uid:userID, name:"", email:emailValue };
  },[currentUser,setUserObject])
  //},[])

  async function handleLogout() {
    setError("")

    try {
      await logout()
      navigate("/login")
    } catch {
      setError("Failed to log out")
    }
  }



  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <strong>Email:</strong> {currentUser.email}
          
          <Link to="/update-profile" className="btn btn-primary w-100 mt-3">
            Update Profile
          </Link>

          <Link to="/english_menu" className="btn btn-primary w-100 mt-3">
            Tjenester for å lære engelsk
          </Link>

          <Link to="/math_menu" className="btn btn-primary w-100 mt-3">
            Tjenester for å lære matematikk
          </Link>

          <Link to="/edit_language" className="btn btn-primary w-100 mt-3">
            Legg til språk
          </Link>

          <Link to="/math_menu" className={userObject?.isAdmin?"btn btn-primary w-100 mt-3":"hidden"}>
            Tjenester for administrator
          </Link>

        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </>
  )
}