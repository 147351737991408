//import firebase from "firebase/compat/app"
//import "firebase/compat/auth"
import { initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

//const auth = getAuth();
/*
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}
*/
const firebaseConfig = {
  apiKey: "AIzaSyDRthBNd37Gw3gOgawi-6PNvBqhxyAewSs",
  authDomain: "kunnleik-af0d6.firebaseapp.com",
  projectId: "kunnleik-af0d6",
  storageBucket: "kunnleik-af0d6.appspot.com",
  messagingSenderId: "672354430395",
  appId: "1:672354430395:web:345bec76f2ab6ee17de89e",
  measurementId: "G-0JDVH7H7MD"};


//const app = firebase.initializeApp(firebaseConfig)
const app = initializeApp(firebaseConfig)
//export const auth = app.auth()
export const auth = getAuth();
export const db = getFirestore(app);
export default app




// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


// Initialize Firebase
